import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import bannerPic from '../../assets/images/goldivox.png';
import goldivoxPic1 from '../../assets/images/goldivox-pic1.jpg';
import goldivoxPic2 from '../../assets/images/goldivox-pic2.png';
import Header from '../../components/Header';


const Goldivox = (props) => (
    <Layout>
        <Header></Header>
        <div id="goldivox">
            <Helmet>
                <title>Dave Martin Programming - Goldivox</title>
                <meta name="description" content="Dave Martin Programming Goldivox rxjs award" />
            </Helmet>

            <section id="banner" className="light" style={{ backgroundImage: `url(${bannerPic})` }}>
                <div className="inner">
                    <header className="major">
                        <h1>Goldivox</h1>
                    </header>
                    <div className="content">
                        <p>An award-winning interactive experience, pushing the boundaries of what's possible in the browser.</p>
                        <ul className="actions">
                            <li><a target="_blank" href="https://web.archive.org/web/20161012215720/https://voiceofgoldivox.com/" className="button next">Try it out (Chrome only)</a></li>
                        </ul>
                    </div>
                </div>
            </section>

            <div id="main">
                <section id="one">
                    <div className="inner">
                        <p>
                            While working at <a href="https://p.ota.to" target="_blank">Potato London</a> in 2016, I was responsible for building an interactive website called "Goldivox".
                            The site was an advert for a company called VocalId, who create voices for people with speech impairments.
                            The idea was that the site would play an animated story of a girl, Goldivox, who was searching for a voice that fit her.
                            At certain points, the animation stops and words appear on the screen, which the user has to read aloud in order to continue.
                    </p>
                    </div>
                </section>
                <section id="two" className="spotlights">
                    <section>
                        <img src={goldivoxPic1} className="image" alt="" />
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>Cutting Edge Technologies</h3>
                                </header>
                                <p>I used ES6, RXJS and the Google Chrome Web Speech API to build Goldivox.</p>
                            </div>
                        </div>
                    </section>
                    <section>
                        <img src={goldivoxPic2} className="image" />
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>Reception</h3>
                                </header>
                                <ul>
                                    <li><a target="_blank" href="https://thefwa.com/cases/goldivox">Won FWA's "Cutting Edge Award" (July 2016)</a></li>
                                    <li><a target="_blank" href="https://www.bizcommunity.com/Article/196/111/146620.html">Nominated at Cannes Lions 2016</a></li>
                                    <li><a target="_blank" href="https://www.adweek.com/brand-marketing/charming-animated-spot-will-help-speech-impaired-find-unique-voice-171049/">"This Charming Animated Spot Will Help the Speech Impaired Find a Unique Voice"</a></li>
                                    <li><a target="_blank" href="https://qz.com/671868/a-cool-new-interactive-ad-campaign-asks-you-to-lend-your-voice-to-the-speech-impaired/">A cool new interactive ad campaign asks you to lend your voice to the speech-impaired</a></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </div>
    </Layout>
)

export default Goldivox